<template>
  <div>
    <eden-table-actions :title="title" :show-search="false">
      <template slot="actions">
        <el-button type="primary" @click="addNewType"> Add New Type </el-button>
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <template v-if="pageData.length">
        <el-table :data="pageData">
          <el-table-column>
            <template slot="header">
              <span>Type</span>
            </template>
            <template v-slot="scope">
              <router-link
                class="text-grey-primary"
                :to="{
                  name: 'services.service-type.edit',
                  params: { id: scope.row.id },
                }"
                >{{ scope.row.service_type }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <span>Areas</span>
            </template>
            <template v-slot="scope">
              <span>{{ scope.row.cleaning_areas.length }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status">
            <template slot="header">
              <span> Status </span>
            </template>
            <template v-slot="scope">
              <el-tag v-if="scope.row.is_active" type="success">
                Activated</el-tag
              >
              <el-tag v-else type="danger"> Deactivated</el-tag>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <span>Minimum price</span>
            </template>
            <template v-slot="scope">
              <span>{{ formatPrice(scope.row.customer_price) }}</span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <span>Added on</span>
            </template>
            <template v-slot="scope">
              <span>{{
                formatDateAndTime(scope.row.created_at, "dddd ddd, m, y")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <span>Updated on</span>
            </template>
            <template v-slot="scope">
              <span>{{
                formatDateAndTime(scope.row.updated_at, "dddd ddd, m, y")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column width="60">
            <template v-slot="scope">
              <service-type-actions
                :service-type="scope.row"
                @updated="getService"
              />
            </template>
          </el-table-column>
        </el-table>
        <eden-pagination
          v-if="showPagination"
          :from="from"
          :to="to"
          :total="total"
          :current-page.sync="page"
        />
      </template>
      <eden-content-empty v-else :text="'No data'" />
    </template>
  </div>
</template>

<script>
import ServiceTypeActions from "@/components/Services/Actions/ServiceTypeActions";
import * as actions from "@/store/action-types";

export default {
  name: "Cleaning",
  components: { ServiceTypeActions },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      showPagination: true,
    };
  },
  computed: {
    service() {
      return this.$route.params.service;
    },
    types() {
      return this.$store.getters.service;
    },
    from() {
      return this.types.pages[this.page].from;
    },
    to() {
      return this.types.pages[this.page].to;
    },
    total() {
      return this.types.total;
    },
    title() {
      return `${this.loading ? 0 : this.total} Types`;
    },
  },
  watch: {
    page() {
      const pageFetched = !!this.types.pages[this.page];
      if (pageFetched) {
        this.setPageData();
      } else {
        this.getService();
      }
    },
  },
  created() {
    const pageFetched = !!this.types.pages[this.page];
    if (pageFetched) {
      this.setPageData();
    } else {
      this.getService();
    }
  },
  methods: {
    getService() {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_SERVICE, {
          service: "cleaning",
          page: this.page,
        })
        .then(() => {
          this.setPageData();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = this.types.pages[this.page].data;
      this.showPagination = true;
    },
    addNewType() {
      this.$router.push({ name: "services.service-type.add" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
